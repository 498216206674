import React, { Component } from "react";
import { connect } from "react-redux";
import Sidebar from "../../components/Common/Sidebar";
import CreateWallet from "./CreateWallet";
import Wallet from "./Wallet";
import NewEscrow from "./Escrow/NewEscrow";
import EscrowDetails from "./Escrow/EscrowDetails";
import FreezedEscrow from "./Escrow/FreezedEscrow";
import History from "./History/History";
import CashToCrypto from "./CashToCrypto/CashToCrypto";
import walletImage from "../../assets/images/Create Wallet-01.svg";
import walletImg from "../../assets/images/Wallet.svg";
import Newescrow from "../../assets/images/New Escrow.svg";
import Historyimg from "../../assets/images/History of Transactions.svg";
import crypto from "../../assets/images/crypto.png";
import withRouter from "../../components/Common/withRouter";

class Finance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      sidebar: {
        title: "Finance",
        menuItems: [
          {
            title: "Create wallet",
            image: walletImage,
            path: "/finance/create-wallet",
          },
          {
            title: "Wallet",
            image: walletImg,
            submenu: [
              {
                title: "Wallet",
                icon: "ri-wallet-line",
                path: "/finance/wallet",
              },
            ],
          },
          {
            title: "New escrow",
            image: Newescrow,
            classNames:"escrow-point",
            submenu: [
              {
                title: "Create",
                icon: "ri-add-circle-line",
                path: "/finance/new-escrow",
              },
            ],
          },
          {
            title: "History of transactions",
            image: Historyimg,
            path: "/finance/history",
            classNames:"historypoint",
          },
          {
            title: "Cash to crypto",
            image: crypto,
            path: "/finance/CashToCrypto",
            classNames:"cashimg",
          },
        ],
      },
    };
  }

  setActiveIndex = (index) => {
    this.setState({ activeIndex: index });
  };
    setComponent = (path) => {
      this.props.router.navigate(path); 
    };
  renderComponentBasedOnPath() {
    const path = window.location.pathname;
    switch (path) {
      case "/finance/create-wallet":
        return <CreateWallet />;
      case "/finance/wallet":
        return <Wallet />;
      case "/finance/new-escrow":
        return <NewEscrow onMenuItemClick={this.setComponent}/>;
      case "/finance/escrow-details":
        return <EscrowDetails  onMenuItemClick={this.setComponent}/>;
      case "/finance/freezed-escrow":
        return <FreezedEscrow  onMenuItemClick={this.setComponent}/>;
        case "/finance/history":
          return <History  onMenuItemClick={this.setComponent}/>;
          case "/finance/CashToCrypto":
            return <CashToCrypto  onMenuItemClick={this.setComponent}/>;
      default:
        return <CreateWallet />;
    }
  }
  render() {
    const { sidebar, activeIndex } = this.state;

    return (
      <div className="page-content">
        <div className="right-msg container">
          <div className="d-lg-flex h-finance">
            <div className="chat-leftsidebar">
            <Sidebar
              sidebar={sidebar}
              onMenuItemClick={(index) => this.setActiveIndex(index)} 
              activeIndex={activeIndex}
            />
            </div>
            <div className="w-100 user-chat mt-4 mt-sm-0">
              {this.renderComponentBasedOnPath()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Finance.propTypes = {
  // Define prop types if necessary
};

const mapStateToProps = (state) => ({
  // Map state to props if necessary
});

const mapDispatchToProps = (dispatch) => ({
  // Map dispatch to props if necessary
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Finance));
