import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import timer from "../../assets/images/timer.png";
import pgp from "../../assets/images/pgp.png";
import bluepgp from "../../assets/images/pgp-blue.png";
import edit from "../../assets/images/edit.png";
import editwhite from "../../assets/images/editwhite.png";
import attach from "../../assets/images/attach.png";
import pencile from "../../assets/images/pencile.png";
import add from "../../assets/images/add.png";
import mute from "../../assets/images/mute.png";
import searchicon from "../../assets/images/searchicon.png";
import block from "../../assets/images/block.png";
import ModelForm from "../../components/Form/Form";
import ModelContact from "../../components/Form/Contact";
import { toast } from "react-toastify";
import src from "../../assets/images/create new.png";
import { Button } from "reactstrap";
import { Tooltip } from "react-tooltip";
import FilePreview from "../../components/messages/Preview";
import unpin from "../../assets/images/chat/Unpin.svg";
import { AvForm, AvField } from "availity-reactstrap-validation";


import {
  Col,
  CardBody,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  Row,
  TabContent,
  TabPane,
  Label, 
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

//Import Images
import user1 from "../../assets/images/user-img.png";
import channelAvatar from "../../assets/images/channel-avatar.svg";

import {
  addMessage,
  getChats,
  getContacts,
  getGroups,
  getMessages,
  updateBlocked,
  createContact,
  updateMuted,
  updateNote,
  deleteChat,
  updateContact,
  clearChat,
  selectUser,
  createChannel,
  addChannelUser,
  removeChannelUser,
  changeChannelAvatar,
  updateChannel,
  messageRead,
  updateUnreadCount,
  removeMember
} from "../../store/actions";

class Chat extends Component {
  constructor(props) {
    super(props);
    this.chatContainerRef = createRef();
    this.state = {
      currentRoomId: 1,
      notification_Menu: false,
      search_Menu: false,
      settings_Menu: false,
      other_Menu: false,
      activeTab: "1",
      Chat_Box_Username: "",
      Chat_Box_User_Status: "",
      Chat_Box_User_isActive: false,
      activeChatId: null,
      curMessage: "",
      selectedUser: {},
      breadcrumbItems: [
        { title: "Resonance", link: "/" },
        { title: "Chat", link: "#" },
      ],
      showProfile: false,
      newContactModal: false,
      newContactName: "",
      newContactAddress: "",
      errors: {
        newContactName: "",
        newContactAddress: "",
        note: "",
      },
      isEditingName: false,
      newName: "",
      noteModal: false,
      user: props.user || {},
      messagefile: null,
      fileType: "",
      fileName: "",
      fileExtension: "",
      downloadProgress: {},
      query: "",
      messageSearchQuery: "",
      channelModal: false,
      isaddUserModalOpen: false,
      newChannelName: "",
      avatar: null,
      description: "",
      channelMembers:[],
      imageURL: null,
      showSelectedMemberModal: false, 
      selectedMember: null,
      isMobile: window.innerWidth <= 800,
    };
    this.messageBox = null;
    this.fileInput = createRef();
    this.docInput = createRef();
    this.channelfileInputRef = createRef();
    this.chatContainerRef = React.createRef(); 
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  componentDidMount() {
    const { onGetGroups, onGetContacts } = this.props;
    const { query } = this.state;
    onGetGroups();
    onGetContacts(query);
    window.addEventListener("resize", this.handleResize);
  }
  componentDidUpdate(prevProps, prevState) {
    const { chats, messages, channelSuccess, channel,onGetContacts,  } = this.props;
    const { selectedUser, user } = this.state;
    if (chats !== prevProps.chats && chats.length > 0) {
      const firstChat = chats[0];
      if (Object.keys(selectedUser).length === 0) {
        this.userChatOpen(firstChat);
      }
    }
    if (messages !== prevProps.messages) {
      messages.forEach(message => {
        if (message.receiver === user.identity.address && message.status !== 'read' && selectedUser.type === 'contact') {
          if (!message.isReadHandled) {
            this.props.messageRead(message);
            message.isReadHandled = true;
          }
        }
      });
    }
    if (chats.length <= 0) {
      if (Object.keys(selectedUser).length !== 0) {
        this.props.selectUser({});
        this.setState({ selectedUser: {} });
      }
    }
    if (this.state.query !== prevState.query) {
      setTimeout(() => {
        this.searchContact();
      }, 500);
    }
    if (channelSuccess !== prevProps.channelSuccess && channel !== prevProps.channel) {
      this.setState({
        newChannelName: "",
        avatar: null,
        description: "",
        channelModal: false,
      });
      setTimeout(()=>{
        onGetContacts(this.state.query);
        }, 500);
        setTimeout(()=>{
          this.userChatOpen(channel);
          this.setState({
            selectedUser:channel,
            isaddUserModalOpen:true
          });
        }, 500);
    }
    if (prevProps.messages.length !== this.props.messages.length) {
      this.scrollToBottom(); 
    }
  }
  handleResize = () => {
    this.setState({ isMobile: window.innerWidth <= 800 });
    const {isMobile} = this.state;
    if(!isMobile){
      const leftsidebar = document.querySelector("#leftsidebar");
      const chatBox = document.querySelector("#chatBox");
      if (leftsidebar && chatBox) {
        leftsidebar.style.display = "block";
        chatBox.style.display = "block";
      }
    }
  };
  toggleNotification = () => {
    this.setState((prevState) => ({
      notification_Menu: !prevState.notification_Menu,
    }));
  };
  toggleSearch = () => {
    this.setState((prevState) => ({
      search_Menu: !prevState.search_Menu,
    }));
  };

  toggleSettings = () => {
    this.setState((prevState) => ({
      settings_Menu: !prevState.settings_Menu,
    }));
  };

  toggleOther = () => {
    this.setState((prevState) => ({
      other_Menu: !prevState.other_Menu,
    }));
  };

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };
  userChatOpen = (chat , callMessages=true , openMessage=false) => {
    const { onGetMessages, selectUser,updateUnreadCount  } = this.props;
    const { user, isMobile } = this.state;
    this.setState({
      activeChatId: chat.id,
      Chat_Box_Username: chat.name,
      messageBox: true,
      currentRoomId: chat.id,
      selectedUser: { ...chat },
      showProfile: false,
      messageSearchQuery: "",
      isEditingName: false,
      curMessage:""
    });
    selectUser({ ...chat });
    if(chat.unreadCount > 0){
      updateUnreadCount(chat.id, 0);
    }
    if(callMessages){
      if (chat.type === "channel") {
        this.setState({channelMembers: []});
        this.setState({channelMembers: chat.users});
        onGetMessages("", "", chat.id);
      } else {
        this.setState({channelMembers: []});
        onGetMessages(chat.address, user.identity.address, "");
      }
    }
    if(isMobile && openMessage){
      const leftsidebar = document.querySelector("#leftsidebar");
      const chatBox = document.querySelector("#chatBox");
      setTimeout(()=>{
        if (leftsidebar && chatBox) {
          leftsidebar.style.display = "none";
          chatBox.style.display = "block";
        }
      },500);
    }
  };
  hideChat =() => {
    const leftsidebar = document.querySelector("#leftsidebar");
    const chatBox = document.querySelector("#chatBox");
    if (leftsidebar && chatBox) {
      leftsidebar.style.display = "block";
      chatBox.style.display = "none";
    }
  }
  addMessage = () => {
    const { onAddMessage } = this.props;
    const {
      curMessage,
      selectedUser,
      user,
      messagefile,
      fileType,
      fileName,
      fileExtension,
    } = this.state;
    const receiver = selectedUser.address;
    const id = selectedUser.id;
    const sender = user.identity.address;
    const type = selectedUser.type;
    let message = {};
    if (!curMessage.trim() && !messagefile) {
      toast.error("Please type a message or select a file to send.");
      return;
    }
    if (type === "contact") {
      message = {
        id: Math.floor(Math.random() * 100),
        receiver,
        sender,
        content: curMessage,
        createdAt: new Date(),
        file: null,
        fileType,
        fileName,
        fileExtension,
      };
    } else {
      message = {
        id: Math.floor(Math.random() * 100),
        channelId: selectedUser.id,
        sender: null,
        receiver: null,
        content: curMessage,
        createdAt: new Date(),
        file: null,
        fileType,
        fileName,
        fileExtension,
      };
    }
    const handleSendMessage = () => {
      this.setState({
        curMessage: "",
        messagefile: null,
        previewFile: null,
        fileType: null,
        fileName: null,
        fileExtension: null,
      });
      onAddMessage(message, id);
      if (this.messageBox) {
        this.messageBox.scrollTop = this.messageBox.scrollHeight + 1000;
      }
    };
    if (messagefile) {
      const reader = new FileReader();
      reader.onload = () => {
        message.file = reader.result;
        handleSendMessage();
      };
      reader.readAsArrayBuffer(messagefile);
    } else {
      handleSendMessage();
    }
  };
  clearChat = () => {
    const { clearChat } = this.props;
    let channelId = null;
    const receiver = this.state.selectedUser.address;
    const sender = this.state.user.identity.address;
    if(this.state.selectedUser.type === 'channel'){
      channelId = this.state.selectedUser.id;
    }
    clearChat(receiver, sender, channelId);
  };
  scrollToBottom = () => {
    if (this.chatContainerRef.current) {
      this.chatContainerRef.current._container.scrollTop = this.chatContainerRef.current._container.scrollHeight;
    }
  };
  onKeyPress = (e) => {
    const { key, value } = e;
    const { currentRoomId, selectedUser } = this.state;
    if (key === "Enter") {
      this.setState({ curMessage: value });
      this.addMessage(currentRoomId, selectedUser.name);
    }
  };
  toggleProfileView = () => {
    this.setState((prevState) => ({
      showProfile: !prevState.showProfile,
    }));
  };
  handleFormSubmit = (e) => {
    e.preventDefault();
    const { newContactName, newContactAddress } = this.state;
    this.props.createContact({
      name: newContactName,
      address: newContactAddress,
    },() => {
      this.setState({
        newContactName: "",
        newContactAddress: "",
        newContactModal: false 
      });
      const { onGetContacts } = this.props;
      onGetContacts();
    });
    
  };
  handleChannelSubmit = (e) => {
    e.preventDefault();
    const { newChannelName, avatar, description, user } = this.state;
    let fileName =null;
    let fileExtension =null;
    if (avatar) {
      fileName = avatar.name;
      fileExtension = fileName.split(".").pop().toLowerCase();
    }
    this.props.createChannel({
      name: newChannelName,
      avatar: avatar,
      description: description,
      fileExtension: fileExtension,
      createdBy: user.identity.address,
    });
  };
  handleNoteSubmit = (e) => {
    e.preventDefault();
    const { id, note } = this.state.selectedUser;
    this.props.updateContact(id, note, "note", "contact",() => {
        this.setState({
          noteModal: false,
          showProfile: true, 
        });
      });
  };
  deleteChat = (type) => {
    const { onGetContacts } = this.props;
    const { id } = this.state.selectedUser;
    this.props.deleteChat(id, type);
    this.setState({ selectedUser: {} });
    setTimeout(() => {
      const { query } = this.state;
      onGetContacts(query);
    }, 200);    
  };
  handleUpdateContact = (field, type = "contact") => {
    const { onGetContacts, updateContact } = this.props;
    const { id, [field]: currentValue } = this.state.selectedUser;
    const newValue = !currentValue;
    updateContact(id, newValue, field, type);
    this.setState((prevState) => ({
      selectedUser: {
        ...prevState.selectedUser,
        [field]: newValue,
      },
    }));
    setTimeout(() => {
      const { query } = this.state;
      onGetContacts(query);
    }, 100);
  };
  handleUnpinContact = (id, itemType) => {
    const { onGetContacts, updateContact } = this.props;
    updateContact(id, false, "pinned", itemType ,() => {
      this.setState({
        selectedUser: {
          ...this.state.selectedUser,
          pinned: false,
        },
      });
      onGetContacts();
    });
  };
  searchContact = () => {
    const { onGetContacts } = this.props;
    const { query } = this.state;
    onGetContacts(query);
  };
  handleInputChange = (event) => {
    const { name, value, files } = event.target;
    if (name === "note") {
      this.setState(
        (prevState) => ({
          selectedUser: {
            ...prevState.selectedUser,
            note: value,
          },
        }),
        () => this.validateField(name, value)
      );
    } else if (name === "avatar") {
      this.setState({ [name]: event.target.files[0] }, () =>
        this.validateField(name, files[0])
      );
    } else {
      this.setState({ [name]: value }, () => this.validateField(name, value));
    }
  };
  handleChannelInputChange = (event) => {
    const { name, value } = event.target;      
      this.setState((prevState) => ({
        selectedUser: {
          ...prevState.selectedUser,
          [name]: value,
        },
      }));
  };
  handleUpdateChannel = () => {
    const { updateChannel } = this.props;
    const { name, description, id } = this.state.selectedUser;
    updateChannel({ name, description, id }, () => {
      const { onGetContacts } = this.props;
      onGetContacts();
      this.setState((prevState) => ({
        showProfile: true 
      }));
    });
  };
  saveNameEdit = () => {
    const { newName } = this.state;
    const { id } = this.state.selectedUser;
      this.props.updateContact(id, newName, "name", "contact", () => {
      this.setState({
        showProfile: true, 
        isEditingName: false,
        selectedUser: {
          ...this.state.selectedUser,
          name: newName,
        },
      });
    });
  };
  
  cancelNameEdit = () => {
    this.setState({
      isEditingName: false,
      newName: this.state.selectedUser.name,
    });
  };
  validateField = (name, value) => {
    let errors = this.state.errors;
    switch (name) {
      case "newContactName":
        if (!value) {
          errors.newContactName = "Name is required.";
        } else if (value.length < 6) {
          errors.newContactName = "Name must be at least 6 characters long.";
        } else if (value.length > 100) {
          errors.newContactName = "Name must be less than 100 characters long.";
        } else {
          errors.newContactName = "";
        }
        break;
      case "newContactAddress":
        if (!value) {
          errors.newContactAddress = "Address is required.";
        }
        break;
      case "note":
        if (!value) {
          errors.newContactAddress = "Note is required.";
        }
        break;
      default:
        break;
    }
    this.setState({ errors });
  };
  toggleNewContactModal = () => {
    this.setState((prevState) => ({
      newContactModal: !prevState.newContactModal,
    }));
  };
  toggleNewChannelModal = () => {
    this.setState((prevState) => ({
      channelModal: !prevState.channelModal,
    }));
  };
  toggleNoteModal = () => {
    this.setState((prevState) => ({
      noteModal: !prevState.noteModal,
    }));
  };
  toggleChannelModal = () => {
    this.setState((prevState) => ({
      channelModal: !prevState.channelModal,
    }));
  };
  toggleaddUserModal = () => {
    this.setState((prevState) => ({
      isaddUserModalOpen: !prevState.isaddUserModalOpen,
    }));
  };
  startEditingName = () => {
    this.setState({
      isEditingName: true,
      newName: this.state.selectedUser.name,
    });
  };
  handleFileChanged = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    const fileName = file.name;
    const fileExtension = fileName.split(".").pop().toLowerCase();
    let fileType = "document";
    let previewUrl = null;
    const fileCategories = {
      image: ["jpg", "jpeg", "png", "gif", "bmp", "webp"],
      video: ["mp4", "mkv", "webm", "avi", "mov"],
      document: ["pdf", "doc", "docx", "xls", "xlsx", "ppt", "pptx", "txt"],
    };
    for (const [type, extensions] of Object.entries(fileCategories)) {
      if (extensions.includes(fileExtension)) {
        fileType = type;
        if (type === "image" || type === "video") {
          previewUrl = URL.createObjectURL(file);
        }
        break;
      }
    }
    this.setState({
      messagefile: file,
      fileType: fileType,
      fileName: fileName,
      fileExtension: fileExtension,
      previewFile: {
        file,
        fileType,
        fileName,
        fileExtension,
        previewUrl,
      },
    });
  };
  openFileDialog = () => {
    this.fileInput.current.click();
  };
  openChannelAvatarDialog = () => {
    this.channelfileInputRef.current.click();
  };
  openDocDialog = () => {
    this.docInput.current.click();
  };
  handlechannelFileChange = (event) => {
    const { id } = this.state.selectedUser;
    const file = event.target.files[0];
    setTimeout(() => {
      this.setState({
        showProfile: true,
      });
    },100);
    if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const allowedExtensions = ["jpg", "jpeg", "png"];
      if (!allowedExtensions.includes(fileExtension)) {
        toast.error("Invalid file type. Please select a JPG, JPEG, or PNG file.");
        return;
      }
      const img = new Image();
      const reader = new FileReader();
      reader.onload = (event) => {
        img.src = event.target.result;
      };
    
      img.onload = () => {
        if (img.width >= 50 && img.width <= 500 && img.height >= 50 && img.height <= 500) {
          this.setState({
            imageURL: URL.createObjectURL(file),
          });
          this.props.changeChannelAvatar(file, id); 
          
          setTimeout(() => {
            this.setState({
              showProfile: true,
            });
          }, 100);
        } else {
          toast.error("Avatar dimensions must be between 50x50 and 500x500 pixels.");
        }
      };
    
      reader.readAsDataURL(file); 
    }    
  };
  handleDownload = (url, messageId, fileName) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onprogress = (event) => {
      const progress = Math.round((event.loaded / event.total) * 100);
      this.setState((prevState) => ({
        downloadProgress: {
          ...prevState.downloadProgress,
          [messageId]: progress,
        },
      }));
    };
    xhr.onload = () => {
      if (xhr.status === 200) {
        const url = window.URL.createObjectURL(xhr.response);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        this.setState((prevState) => ({
          downloadProgress: {
            ...prevState.downloadProgress,
            [messageId]: 100,
          },
        }));
      }
    };
    xhr.onerror = () => {
      toast.error("Download failed. Please try again.");
    };
    xhr.send();
  };
  handleCopy = () => {
    const { address } = this.state.selectedUser;
    navigator.clipboard.writeText(address).then(
      () => {
        toast.success("Resonance ID copied successfully.");
      },
      (err) => {
        toast.error("Could not copy Resonance ID : ", err);
      }
    );
  };
  getMessagesGroupedByDate = (messages, searchQuery) => {
    let filteredMessages = messages;
    if (searchQuery.trim()) {
      filteredMessages = messages.filter(
        (message) =>
          message.content.toLowerCase().includes(searchQuery.toLowerCase()) ||
          (message.fileName &&
            message.fileName.toLowerCase().includes(searchQuery.toLowerCase()))
      );
    }
    return filteredMessages.reduce((acc, message) => {
      const date = moment(message.createdAt).format("ddd, DD MMMM YYYY");
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(message);
      return acc;
    }, {});
  };
  handleRemoveFile = () => {
    this.setState({
      previewFile: {},
    });
  };
  handleAddUserSubmit = (selectedContacts, values) => {
    const { addChannelUser,onGetContacts } = this.props;
    const { id } = this.state.selectedUser;
    addChannelUser(id, selectedContacts , (updatedUsers) => {
      this.setState(prevState => ({
        selectedUser: {
          ...prevState.selectedUser,
          users: updatedUsers,
        },
        channelMembers:updatedUsers
      }));
      setTimeout(() => {
        const { query } = this.state;
        onGetContacts(query);
      }, 500);
    });
    this.toggleaddUserModal();
  };
  removeMember(id, address){
    const { removeMember } = this.props;
    removeMember(id, address,(updatedUsers) => {
    this.setState(prevState => ({
      selectedUser: {
        ...prevState.selectedUser,
        users: updatedUsers,
      },
      channelMembers:updatedUsers
    }));
  });
  }
  handleLeaveChannel = () => {
    const { removeChannelUser, onGetContacts } = this.props;
    const { id } = this.state.selectedUser;
    const { address } = this.state.user.identity;
    removeChannelUser(id, address);
    setTimeout(() => {
      const { query } = this.state;
      onGetContacts(query);
    }, 500);
  };
  viewMember(member){
    this.setState({
      selectedMember: member,
      showSelectedMemberModal: true,
    });
  };

  handleCloseModal(){
    this.setState({
      showSelectedMemberModal: false,
      selectedMember: null, 
    });
  };

  render() {
    const { chats } = this.props;
    let messages = this.props.messages || [];
    const {
      showProfile,
      selectedUser,
      newContactModal,
      newContactName,
      newContactAddress,
      errors,
      noteModal,
      isEditingName,
      newName,
      query,
      messageSearchQuery,
      previewFile,
      newChannelName,
      channelModal,
      avatar,
      description,
      user,
      isaddUserModalOpen,
      channelMembers,
      activeChatId,
      imageURL,
      showSelectedMemberModal,
      selectedMember,
      isMobile
    } = this.state;
    const messagesGroupedByDate = this.getMessagesGroupedByDate(
      messages,
      messageSearchQuery
    );
    const fields = [
      {
        name: "newContactName",
        label: "Name",
        value: newContactName,
        type: "text",
        placeholder: "Contact name",
        required: true,
        maxLength: 100,
        minLength:6
      },
      {
        name: "newContactAddress",
        label: "Address",
        value: newContactAddress,
        type: "text",
        placeholder: "Contact address",
        required: true,
      },
    ];
    const channelFields = [
      {
        name: "avatar",
        label: "Channel Avatar",
        value: avatar,
        type: "file",
        placeholder: "Upload an avatar",
        required: false,
      },
      {
        name: "newChannelName",
        label: "Channel Name",
        value: newChannelName,
        type: "text",
        placeholder: "Channel name",
        required: true,
        maxLength: 100,
        minLength:6
      },
      {
        name: "description",
        label: "Channel Description",
        value: description,
        type: "textarea",
        placeholder: "Channel description",
        required: true,
        minLength:10
      },
    ];
    const notefields = [
      {
        name: "note",
        label: "Note",
        value: selectedUser.note,
        type: "textarea",
        placeholder: "Note",
        required: true,
      },
    ];
    const avatarURL = imageURL ? imageURL : (selectedUser.avatar || (selectedUser.type === "contact" ? user1 : channelAvatar));
    let divStyle1 = {
      height: '130px',
      width: '130px',
      backgroundImage: `url(${avatarURL})`,
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      cursor: 'pointer',
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container className="right-msg p-0">
            {/* Render Breadcrumb */}
            {/* <Breadcrumbs
              title="Chat"
              breadcrumbItems={this.state.breadcrumbItems}
            /> */}

            <div className="d-lg-flex mb-0 chat-side">
              <div className="chat-leftsidebar" id="leftsidebar">
                <div className="head-cht border-bottom">
                  <div className="d-flex align-items-center">
                    <div className="flex-1 ">
                      <h3 className="msg-heading">Messages</h3>
                    </div>
                    <div className="edit-icon">
                      <Dropdown
                        isOpen={this.state.other4}
                        toggle={() =>
                          this.setState({ other4: !this.state.other4 })
                        }
                        data-tooltip-id="chat-tooltip"
                        data-tooltip-content="Create new contact."
                      >
                        <DropdownToggle
                          className="btn nav-btn edit-drop"
                          tag="i"
                        >
                          <img src={edit} className="edit-default" alt="Edit" />
                          <img
                            src={editwhite}
                            className="edit-mobile"
                            alt="Edit"
                          />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end-cls drop-menu">
                          <DropdownItem
                            href="#"
                            className="drop-icons"
                            onClick={this.toggleNewContactModal}
                          >
                            <i className="ri-chat-new-line"></i>
                            New Chat
                          </DropdownItem>
                          <DropdownItem
                            href="#"
                            className="drop-icons"
                            onClick={this.toggleNewChannelModal}
                          >
                            <i className="fas fa-broadcast-tower"></i>
                            New Channel
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                      <Tooltip id="chat-tooltip" />
                    </div>
                  </div>
                </div>
                <div className="main-chat-point">
                  <CardBody className="py-2">
                    <div className="search-box chat-search-box">
                      <div className="position-relative">
                        <Input
                          placeholder="Search chat..."
                          name="query"
                          type="text"
                          value={query}
                          onChange={this.handleInputChange}
                        />
                        <i
                          className="ri-search-line search-icon"
                          onClick={this.searchContact}
                        ></i>
                      </div>
                    </div>
                  </CardBody>
                <TabContent activeTab={this.state.activeTab} className="py-3">
                  <TabPane tabId="1">
                    <div>
                      <ul className="list-unstyled chat-list side-height-cls">
                        <PerfectScrollbar className="main-point-cls "
                          ref={this.chatContainerRef}
                        >
                          {chats.length === 0 ? (
                            <li className="no-contacts text-center">
                              <p>No contacts found</p>
                            </li>
                          ) : (
                            chats.map((chat, key) => (
                              <li
                                key={key}
                                className={`chat-side-bar ${chat.id === activeChatId ? "active" : ""}`}
                              >
                                <Link
                                  to="#"
                                  onClick={() => {
                                    this.userChatOpen(chat, true, true);
                                  }}
                                >
                                  <div className="d-flex align-items-center w-100">
                                    <div
                                      className={ `header-contact 
                                         ${chat.status == "active"
                                          ? "user-img online align-self-center me-3"
                                          : "user-img away align-self-center me-3"}`
                                      }
                                    >
                                      <img
                                        src={chat.avatar ? chat.avatar : (chat.type === "contact" ? user1 : channelAvatar)}
                                        className="rounded-circle avatar-sm"
                                        alt="avatar"
                                      />
                                      {chat.type === "contact" && (
                                        <i
                                          className={`mdi mdi-circle align-middle me-1 ${
                                            chat.status === "active" ? "text-success" : "text-secondary"
                                          }`}
                                        ></i>
                                      )}
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center flex-grow-1 overflow-hidden">
                                      <div className="d-flex flex-column">
                                        <h5 className="text-truncate chat-name m-0">
                                          {chat.name.length > 16 ? `${chat.name.substring(0, 13)}...` : chat.name}
                                        </h5>
                                        <p className="text-truncate chat d-flex m-0">
                                        {chat.message ? (
                                            <>
                                              {chat.message.content ? (
                                                <>
                                                  {chat.message.content.length > 16
                                                    ? `${chat.message.content.substring(0, 16)}...`
                                                    : chat.message.content}
                                                </>
                                              ) : (
                                                <>{chat.message.type}</>
                                              )}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </p>
                                      </div>
                                      <div className="d-flex flex-column align-items-end">
                                        <small className="chat-time  m-0">
                                          {chat.lastMessageTimestamp ? moment(chat.lastMessageTimestamp).format("hh:mm A") : ""}
                                        </small>
                                        <div className="d-flex">
                                        {chat.unreadCount > 0 && (
                                          <span className="pull-right mx-1 count-cls" >
                                            {chat.unreadCount}
                                          </span>
                                        )}
                                        {chat.pinned && (
                                          <img
                                            alt="unpin"
                                            src={unpin}
                                            className="mt-1"
                                            style={{ width: '16px', height: '16px' }}
                                            onClick={() => this.handleUnpinContact(chat.id, chat.type)}
                                            data-tooltip-id="unpin-tooltip"
                                            data-tooltip-content="Unpin chat."
                                          />
                                        )}
                                        <Tooltip id="unpin-tooltip" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  </Link>
                                </li>
                              ))
                            )}
                          </PerfectScrollbar>
                        </ul>
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
              <div className="w-100 user-chat mt-4 mt-sm-0 user-chat-over" id="chatBox">
                {Object.keys(selectedUser).length > 0 && (
                  <div className="user-chat-border">
                    {showProfile ? (
                      <div className="contact-info">
                        <i
                          className="ri-arrow-left-line"
                          onClick={this.toggleProfileView}
                        ></i>{" "}
                        <span>
                          <h4>{selectedUser.type === "contact" ? "Contact Info" : "Channel Info"}</h4>
                        </span>
                      </div>
                    ) : (
                      <Row>
                        <Col md={5} xs={6}>
                            <div className="header-contact">
                            {(isMobile && !showProfile) && (
                              <i
                              className="ri-arrow-left-line back-arrow"
                                onClick={this.hideChat}
                              ></i>
                            )}
                              <div className="align-self-center me-3">
                                {selectedUser.avatar ? (
                                  <img
                                    src={selectedUser.avatar}
                                    className="avatar-sm rounded-circle"
                                    alt="avatar"
                                    onClick={this.toggleProfileView}
                                  />
                                ) : (
                                  <img
                                    src={selectedUser.type === "contact" ? user1 : channelAvatar}
                                    className="avatar-sm rounded-circle"
                                    alt="avatar"
                                    onClick={this.toggleProfileView}
                                  />
                                )}
                                {selectedUser.type === "contact" && (
                                  <>
                                    {selectedUser.status === "active"  ? (
                                      <>
                                        <i className="mdi mdi-circle text-success align-middle me-1 main-clas-dot"></i>
                                      </>
                                        ):(
                                          <>
                                            <i className="mdi mdi-circle text-secondary align-middle me-1 main-clas-dot"></i>
                                          </>
                                    )}
                                  </>
                                )}
                              </div>
                            <h5 className="main-chat text-truncate" onClick={this.toggleProfileView}>
                              {this.state.Chat_Box_Username}
                            </h5>
                            {selectedUser.type === "contact" && (
                              <>
                                {/* <img
                                  src={pgp}
                                  className="pgp-img"
                                  alt="PGP Icon"
                                />
                                <p className="pgp">PGP</p> */}
                              </>
                            )}
                            </div>
                        </Col>
                        <Col md={7} xs={6}>
                          <ul className="list-inline user-chat-nav text-end mb-0 d-flex justify-content-end">
                            <li className="list-inline-item d-inline-block d-sm-none ">
                              <Dropdown
                                isOpen={this.state.settings}
                                toggle={() =>
                                  this.setState({
                                    settings: !this.state.settings,
                                  })
                                }
                              >
                                <DropdownToggle
                                  className="btn nav-btn dropdown-toggle"
                                  type="button"
                                >
                                  <i className="mdi mdi-magnify"></i>
                                </DropdownToggle>
                                <DropdownMenu end className="dropdown-menu-md">
                                    <div className="search-box">
                                      <div className="position-relative">
                                      <input type="text" 
                                          className="search-input"
                                          placeholder="Search in messages..."
                                          name="messageSearchQuery"
                                          value={messageSearchQuery}
                                          onChange={this.handleInputChange}
                                          style={{
                                            backgroundImage: `url(${searchicon})`,
                                          }}
                                        />
                                      </div>
                                    </div>
                                </DropdownMenu>
                              </Dropdown>
                            </li>
                            <li className="d-none d-sm-inline-block">
                              <div className="search-box me-2">
                                <div className="position-relative">
                                <input type="text" 
                                    className="search-input"
                                    placeholder="Search in messages..."
                                    name="messageSearchQuery"
                                    value={messageSearchQuery}
                                    onChange={this.handleInputChange}
                                    style={{
                                      backgroundImage: `url(${searchicon})`,
                                    }}
                                  />
                                  {/* <i className="mdi mdi-magnify search-icon"></i> */}
                                </div>
                              </div>
                            </li>
                            <li className="list-inline-item">
                              <Dropdown
                                isOpen={this.state.other2}
                                toggle={() =>
                                  this.setState({ other2: !this.state.other2 })
                                }
                              >
                                <DropdownToggle
                                  className="btn nav-btn "
                                  tag="i"
                                >
                                  <i className="mdi mdi-dots-horizontal"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end drop-menu">
                                  {selectedUser.type === "channel" ? (
                                    <>
                                      <DropdownItem
                                        onClick={() => this.handleUpdateContact("pinned", "channel")}
                                      >
                                        {selectedUser.pinned ? "Unpin": "Pin"} conversation
                                      </DropdownItem>
                                      <DropdownItem onClick={this.clearChat}>
                                        Clear chat
                                      </DropdownItem>
                                      {user.identity.address ===
                                        selectedUser.createdBy ? (
                                        <DropdownItem
                                          className="inner-clr"
                                          onClick={() =>
                                            this.deleteChat("channel")
                                          }
                                        >
                                          {" "}
                                          Delete Channel
                                        </DropdownItem>
                                      ):
                                      (
                                        <DropdownItem
                                          onClick={() =>
                                            this.handleLeaveChannel()
                                          }
                                        >
                                          {" "}
                                          Leave Channel
                                        </DropdownItem>)
                                      }
                                      <DropdownItem
                                        href="#"
                                        onClick={this.toggleProfileView}
                                      >
                                        Show channel details
                                      </DropdownItem>
                                    </>
                                  ) : (
                                    <>
                                      <DropdownItem
                                        onClick={() =>
                                          this.handleUpdateContact(
                                            "pinned",
                                            "contact"
                                          )
                                        }
                                      >
                                        {" "}
                                        {this.state.selectedUser.pinned
                                          ? "Unpin"
                                          : "Pin "}{" "}
                                        conversation
                                      </DropdownItem>
                                      <DropdownItem onClick={() =>
                                        this.handleUpdateContact("muted")}>
                                      {selectedUser.muted ? "Unmute" : "Mute"} notification
                                      </DropdownItem>
                                      <DropdownItem onClick={this.handleCopy}>
                                        Copy resonance ID{" "}
                                      </DropdownItem>
                                      <DropdownItem onClick={this.clearChat}>
                                        Clear chat
                                      </DropdownItem>
                                      <DropdownItem
                                        href="#"
                                        className="inner-clr"
                                        onClick={() =>
                                          this.deleteChat("contact")
                                        }
                                      >
                                        Delete chat
                                      </DropdownItem>
                                      <DropdownItem
                                        href="#"
                                        onClick={this.toggleProfileView}
                                      >
                                        Show details
                                      </DropdownItem>
                                    </>
                                  )}
                                </DropdownMenu>
                              </Dropdown>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    )}
                  </div>
                )}
                <div className="">
                  {showProfile ? (
                    <>
                      {selectedUser.type === "contact" ?
                        (
                          <div className="chat-conversation p-3 chat-conversation-height">
                            <div className="row justify-content-center main-show-details-cls text-center">
                              <div className="width-660">
                                <div className="conversation-list user-profile profile-options">
                                  <div className="dp-profile">
                                    <img
                                      src={
                                        selectedUser.avatar
                                          ? selectedUser.avatar
                                          : user1
                                      }
                                      alt="avatar"
                                      className="rounded-circle avatar-lgg"
                                    />
                                    {selectedUser.type === "contact" && (
                                    <>
                                      {selectedUser.status === "active"  ? (
                                        <>
                                          <i className="mdi mdi-circle text-success align-middle me-1"></i>
                                        </>
                                      ):(
                                        <>
                                          <i className="mdi mdi-circle text-secondary align-middle me-1"></i>
                                        </>
                                      )}
                                    </>
                                  )}
                                  </div>
                                </div>
                                <div className="ctext-wrap">
                                  <div className="conversation-name edit-name">
                                    {isEditingName ? (
                                      <div className="edit-btns">
                                        <input
                                          name="newName"
                                          type="text"
                                          value={newName}
                                          onChange={this.handleInputChange}
                                          className="form-control edit-control"
                                          maxLength="100"
                                          minLength="6"
                                        />
                                        <Button
                                          onClick={this.cancelNameEdit}
                                          className="reject-btn cncl-btn"
                                        >
                                          <i className="fas fa-times"></i>
                                        </Button>
                                        <Button
                                          onClick={this.saveNameEdit}
                                          className="btn cryto-btn savebtns"
                                        >
                                          <i className="fas fa-check"></i>
                                        </Button>
                                      </div>
                                    ) : (
                                      <div className="d-flex">
                                        <h4 className="mb-1">
                                          {selectedUser.name.length > 16 ? `${selectedUser.name.substring(0, 13)}...` : selectedUser.name}
                                        </h4>
                                        <img
                                          src={pencile}
                                          onClick={this.startEditingName}
                                          alt="pencil"
                                          data-tooltip-id="edit-tooltip"
                                          data-tooltip-content="Edit contact name."
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <Tooltip id="edit-tooltip" />
                                <div className="text-center">
                                  <p className="text-muted mb-0">
                                    Status: {selectedUser.status}
                                  </p>
                                  <div className="mt-3 profile-btns">
                                    <button
                                      className="btn btn-outline-primary btn-sm add-note"
                                      onClick={this.toggleNoteModal}
                                    >
                                      <img src={add} alt="add" />
                                      <p>Add Note</p>
                                    </button>
                                    <button
                                      className="btn btn-outline-secondary btn-sm ms-2 add-note"
                                      onClick={() =>
                                        this.handleUpdateContact("muted")
                                      }
                                    >
                                      <img src={mute} alt="mute" />
                                      <p>
                                        {selectedUser.muted ? "Unmute" : "Mute"}
                                      </p>
                                    </button>
                                    <button
                                      className="btn btn-outline-danger btn-sm ms-2 block-cls"
                                      onClick={() =>
                                        this.handleUpdateContact("blocked")
                                      }
                                    >
                                      <img src={block} alt="block" />
                                      <p>
                                        {selectedUser.blocked ? "Unblock" : "Block"}
                                      </p>
                                    </button>
                                  </div>
                                </div>
                                <div className="mt-4 history-pgp">
                                  <Link className="btn btn-link text-decoration-none w-100">
                                    <div className="history-detial transaction">
                                      <div className="text-left d-flex timer-cls">
                                        <img src={timer}  alt="timer"/>
                                        <span>History of transactions</span>
                                      </div>
                                      <div className="arr-right">
                                        <i className=" ri-arrow-right-s-line"></i>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                        :
                        (
                          <div className="page-profile">
                          <div className="d-lg-flex chat-side">
                            <div className="w-100 user-chat-cls mt-sm-0 chennal-cls">
                              <div className="px-lg-2">
                                <div className="d-flex justify-content-center align-items-center">
                                  <div className="profile-w">
                                    <div className="chat-conversation p-3 chat-conversation-height">
                                        <div className="d-flex justify-content-center">
                                          <div className="second-img rounded-circle" 
                                            style={divStyle1} 
                                            onClick={user.identity.address !== selectedUser.createdBy ? null : this.openChannelAvatarDialog}
                                            {...(user.identity.address === selectedUser.createdBy && {
                                              "data-tooltip-id": "avatar-tooltip",
                                              "data-tooltip-content": "Change channel avatar.",
                                            })}
                                          >
                                          </div>
                                          <Tooltip id="avatar-tooltip" />
                                          <input
                                            type="file"
                                            ref={this.channelfileInputRef}
                                            style={{ display: "none" }}
                                            accept=".jpg,.jpeg,.png"
                                            onChange={this.handlechannelFileChange}
                                          />
                                        </div>
                                        <div className="text-center my-2">
                                          <p>Channel: {selectedUser.users.length > 0 ? selectedUser.users.length  : 0} {selectedUser.users.length > 1 ? "Members" : "Member"}</p>
                                        </div>
                                        <AvForm onValidSubmit={this.handleUpdateChannel}>

                                        {user.identity.address === selectedUser.createdBy && (
                                          <div className="text-right">
                                          <span className=" add-member-icon" onClick={this.toggleaddUserModal}
                                            data-tooltip-id="add-members"
                                            data-tooltip-content="Add new members to the channel."
                                          >
                                            <i className="fa fa-user-plus" aria-hidden="true"></i>
                                          </span>
                                          <button class="add-member-icon border-0 bg-none text-center p-0 rounded-circle" type="submit"
                                            data-tooltip-id="update-channel"
                                            data-tooltip-content="Update channel."
                                          >
                                           <i className="fa fa-check m-0" aria-hidden="true"></i>
                                         </button>
                                         <Tooltip id="update-channel"></Tooltip>
                                         <Tooltip id="add-members"></Tooltip>
                                         </div>
                                        )}
                                       
                                          <div className="id-mar">
                                            <Label className="form-label id-color">Channel Name</Label>
                                            <div className="input-with-icon id-input">
                                              <AvField
                                                name="name"
                                                type="text"
                                                className="form-control"
                                                value={selectedUser.name}
                                                readOnly={user.identity.address !== selectedUser.createdBy}
                                                onChange={this.handleChannelInputChange}
                                                validate={{
                                                  required: { value: true, errorMessage: "Channel name is required." },
                                                  minLength: { value: 6, errorMessage: "Channel name must be at least 6 characters." },
                                                  maxLength: { value: 100, errorMessage: "Channel name cannot exceed 100 characters." },
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <div className="id-mar bg-cls-txt">
                                            <Label className="form-label id-color">Description</Label>
                                            <div className="input-with-icon id-input ">
                                              <AvField
                                                name="description"
                                                type="textarea"
                                                value={selectedUser.description}
                                                className="form-control custom-placeholder mb-0"
                                                placeholder="Channel description"
                                                readOnly={user.identity.address !== selectedUser.createdBy}
                                                onChange={this.handleChannelInputChange}
                                                validate={{
                                                  required: { value: true, errorMessage: "Description is required." },
                                                  minLength: { value: 10, errorMessage: "Description must be at least 10 characters." },
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <div>
                                          {selectedUser.users.length === 0 ? (
                                            <p className="text-center">No member found.</p>
                                          ) : (
                                            <>
                                            <Label className="form-label id-color">Members</Label>
                                            <PerfectScrollbar className="member-scroll">
                                            {selectedUser.users.map((member, index) => (
                                                <div
                                                  key={member.id}
                                                  className={`contact-item justify-content-between my-1 ${index === selectedUser.users.length - 1 ? 'last-contact my-1' : ''}`}
                                                  style={{
                                                    borderLeft:'3px solid #1877f2',
                                                    backgroundColor: '#e6f0ff',
                                                    cursor: 'pointer',
                                                    border: '1px solid #D6E8FF',
                                                    borderRadius:' 5px',
                                                  }}
                                                >
                                                  <Label className="d-flex align-items-center">
                                                    <img
                                                      src={member.avatar ? member.avatar : user1}
                                                      alt={member.name}
                                                      className="rounded-circle avatar-sm member-ava"
                                                    />
                                                    <span className="contact-name">
                                                      {member.nickname ? (
                                                        <>
                                                          {member.nickname.length > 16 ? `${member.nickname.substring(0, 15)}...` : member.nickname}
                                                        </>
                                                      ) :
                                                      <>
                                                       {member.identityId.length > 16 ? `${member.identityId.substring(0, 15)}...` : member.identityId}
                                                      </>
                                                      }
                                                    </span>
                                                   
                                                  </Label>
                                                  <span className="contact-name mx-2">
                                                  <div className="member-info">
                                                      {member.role === 'admin' ? (
                                                        <span className="badge bg-success py-2">Group Admin</span>
                                                      ) : (
                                                        <>
                                                        {user.identity.address === selectedUser.createdBy && ( 
                                                          <>
                                                            <i
                                                              className="fa fa-trash text-danger" 
                                                              onClick={() => this.removeMember(selectedUser.id, member.identityId)}
                                                              style={{ cursor: 'pointer', marginRight: '10px' }}
                                                              title="Remove Member"
                                                              data-tooltip-id="remove-member"
                                                              data-tooltip-content="Remove member from channel."
                                                            ></i>
                                                          </>)}
                                                          <i
                                                            className="fa fa-eye" 
                                                            onClick={() => this.viewMember(member)}
                                                            style={{ cursor: 'pointer', marginRight: '10px' }}
                                                            title="View member details"
                                                            data-tooltip-id="remove-member"
                                                            data-tooltip-content="View member details."
                                                          ></i>
                                                          </>
                                                      )}
                                                    </div>
                                                  </span>
                                                </div>
                                            ))}
                                            </PerfectScrollbar>
                                            </>
                                          )}
                                          <Tooltip id="remove-member"></Tooltip>
                                          </div>
                                        </AvForm>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        )
                      }
                    </>
                  ) : (
                    <div>
                      {Object.keys(selectedUser).length > 0 ? (
                        <div>
                          <PerfectScrollbar className="chat-conversation-height-cls">
                          <div className="chat-conversation p-3">
                            <ul className="list-unstyled mb-0">
                              <PerfectScrollbar className="main-point-cls" ref={this.chatContainerRef}>
                                {Object.keys(messagesGroupedByDate).length >
                                0 ? (
                                  Object.keys(messagesGroupedByDate).map(
                                    (date, idx) => (
                                      <React.Fragment key={idx}>
                                        <li className="text-center">{date}</li>
                                        {messagesGroupedByDate[date].map(
                                          (message, key) => (
                                            <li
                                              key={key}
                                              className={
                                                message.sender ===
                                                selectedUser.address
                                                  ? "right"
                                                  : ""
                                              }
                                              style={{ paddingRight: "20px" }}
                                            >
                                              <div className="conversation-list">
                                                <div className="ctext-wrap">
                                                  <p className="chat-time mb-0">
                                                    {moment(
                                                      message.createdAt
                                                    ).format("hh:mm A")}
                                                  </p>
                                                  <div className="ctext-wrap-content">
                                                    {message.fileUrl ? (
                                                      message.fileType ===
                                                      "image" ? (
                                                        <div>
                                                          <img
                                                            src={
                                                              message.fileUrl
                                                            }
                                                            alt={
                                                              message.fileName ||
                                                              "Image"
                                                            }
                                                            style={{
                                                              maxWidth: "150px",
                                                              maxHeight:
                                                                "150px",
                                                            }}
                                                          />
                                                          <p className="mb-0">
                                                            {message.content}
                                                          </p>
                                                        </div>
                                                      ) : message.fileType ===
                                                        "video" ? (
                                                        <div>
                                                          <video
                                                            controls
                                                            src={
                                                              message.fileUrl
                                                            }
                                                            style={{
                                                              maxWidth: "30px",
                                                            }}
                                                          >
                                                            Your browser does
                                                            not support the
                                                            video tag.
                                                          </video>
                                                          <p className="mb-0">
                                                            {message.content}
                                                          </p>
                                                        </div>
                                                      ) : message.fileType ===
                                                        "document" ? (
                                                        <div>
                                                          <a
                                                            href="#"
                                                            onClick={() =>
                                                              this.handleDownload(
                                                                message.fileUrl,
                                                                message.id,
                                                                message.fileName
                                                              )
                                                            }
                                                          >
                                                            <i className="fa fa-file" />
                                                          </a>
                                                          {this.state
                                                            .downloadProgress[
                                                            message.id
                                                          ] !== undefined && (
                                                            <span>
                                                              {" "}
                                                              -{" "}
                                                              {
                                                                this.state
                                                                  .downloadProgress[
                                                                  message.id
                                                                ]
                                                              }
                                                              %
                                                            </span>
                                                          )}
                                                        </div>
                                                      ) : (
                                                        <p className="mb-0">
                                                          {message.content}
                                                        </p>
                                                      )
                                                    ) : (
                                                      <p className="mb-0">
                                                        {message.content}
                                                      </p>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              {message.receiver ===
                                                selectedUser.address && (
                                                <div className="message-status">
                                                  {message.status ===
                                                    "unread" && (
                                                      <i className="fas fa-check"></i>
                                                  )}
                                                  {message.status ===
                                                    "delivered" && (
                                                    <span className="double-tick text-muted">
                                                      <i className='fas fa-check-double'></i>
                                                    </span>
                                                  )}
                                                  {message.status ===
                                                    "read" && (
                                                    <span className="double-tick text-primary" >
                                                      <i className='fas fa-check-double'></i>
                                                    </span>
                                                  )}
                                                </div>
                                              )}
                                            </li>
                                          )
                                        )}
                                      </React.Fragment>
                                    )
                                  )
                                ) : (
                                  <li className="text-center">
                                    No messages to display.
                                  </li>
                                )}
                              </PerfectScrollbar>
                            </ul>
                          </div>
                          </PerfectScrollbar>
                          {previewFile && (
                            <div className="previews">
                              <FilePreview
                                preview={previewFile}
                                onRemove={this.handleRemoveFile}
                                className="mx-2"
                              />
                            </div>
                          )}
                          <div className="chat-input-section">
                            {selectedUser.blocked ? (
                              <p className="text-center">
                                <a
                                  className="py-2 pointer"
                                  href="#"
                                  onClick={() =>
                                    this.handleUpdateContact("blocked")
                                  }
                                >
                                  <p  className="text-icon">
                                    <i className="fa fa-lock p-0"></i> You blocked this contact. Tap to unblock.
                                  </p>
                                </a>
                              </p>
                            ) : (
                              <Row>
                                {selectedUser.type === "contact" ? (
                                  <>
                                    <li className="list-inline-item col-lg-1 d-flex justify-content-center attach-file-cls p-0">
                                      <Dropdown
                                        isOpen={this.state.other3}
                                        toggle={() =>
                                          this.setState({
                                            other3: !this.state.other3,
                                          })
                                        }
                                      >
                                        <DropdownToggle
                                          className="btn nav-btn "
                                          tag="i"
                                        >
                                          <img src={attach} />
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-end-cls">
                                          <DropdownItem
                                            href="#"
                                            className="drop-icons"
                                            // onClick={this.openFileDialog}
                                          >
                                            <i className="fa fa-image"></i>
                                            Photos or videos
                                          </DropdownItem>
                                          <DropdownItem
                                            href="#"
                                            className="drop-icons"
                                            // onClick={this.openDocDialog}
                                          >
                                            <i className="ri-file-line"></i>
                                            Document
                                          </DropdownItem>
                                          <DropdownItem
                                            href="#"
                                            className="drop-icons"
                                          >
                                            <i className="ri-currency-line"></i>
                                            Create payment
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </Dropdown>
                                    </li>
                                    <Col className="p-0">
                                      <div className="position-relative">
                                        <Input
                                          type="text"
                                          value={this.state.curMessage}
                                          onChange={(e) => {
                                            this.setState({
                                              curMessage: e.target.value,
                                            });
                                          }}
                                          className="form-control chat-input"
                                          placeholder="Type your message..."
                                          onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                              this.addMessage(selectedUser.name);
                                            }
                                          }}
                                        />
                                      </div>
                                    </Col>
                                    <Col xs={{ size: "auto" }}>
                                      <div className="send-msg">
                                        <i
                                          type="button"
                                          color="primary"
                                          onClick={() =>
                                            this.addMessage(selectedUser.name)
                                          }
                                          className="mdi mdi-send"
                                        ></i>
                                      </div>
                                    </Col>
                                  </>
                                ) : (
                                  <>
                                    {user.identity.address ===
                                      selectedUser.createdBy ? (
                                      <>
                                        <li className="list-inline-item col-lg-1 d-flex justify-content-center attach-file-cls p-0">
                                          <Dropdown
                                            isOpen={this.state.other3}
                                            toggle={() =>
                                              this.setState({
                                                other3: !this.state.other3,
                                              })
                                            }
                                          >
                                            <DropdownToggle
                                              className="btn nav-btn "
                                              tag="i"
                                            >
                                              <img src={attach} />
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-end-cls">
                                              <DropdownItem
                                                href="#"
                                                className="drop-icons"
                                                onClick={this.openFileDialog}
                                              >
                                                <i className="fa fa-image"></i>
                                                Photos or videos
                                              </DropdownItem>
                                              <DropdownItem
                                                href="#"
                                                className="drop-icons"
                                                onClick={this.openDocDialog}
                                              >
                                                <i className="ri-file-line"></i>
                                                Document
                                              </DropdownItem>
                                              <DropdownItem
                                                href="#"
                                                className="drop-icons"
                                              >
                                                <i className="ri-currency-line"></i>
                                                Create payment
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </Dropdown>
                                        </li>
                                        <Col className="p-0">
                                          <div className="position-relative">
                                            <Input
                                              type="text"
                                              value={this.state.curMessage}
                                              onChange={(e) => {
                                                this.setState({
                                                  curMessage: e.target.value,
                                                });
                                              }}
                                              onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                  this.addMessage(selectedUser.name);
                                                }
                                              }}
                                              className="form-control chat-input"
                                              placeholder="Type your message..."
                                            />
                                          </div>
                                        </Col>
                                        <Col xs={{ size: "auto" }}>
                                          <div className="send-msg">
                                            <i
                                              type="button"
                                              color="primary"
                                              onClick={() =>
                                                this.addMessage(
                                                  selectedUser.name
                                                )
                                              }
                                              className="mdi mdi-send"
                                            ></i>
                                          </div>
                                        </Col>
                                      </>
                                    )
                                  :(
                                    <>
                                    <a
                                      className="py-2 "
                                    >
                                      <p className="text-center">
                                        Only channel admin can send messages.
                                      </p>
                                    </a>
                                    </>
                                  )}
                                  </>
                                )}
                              </Row>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <Container>
                            <div className="cancel-point">
                              <div className="width-cls">
                                <div className="create-new-cls">
                                  <div
                                    className="text-center"
                                    onClick={this.toggleNewContactModal}
                                  >
                                    <div className="create-new">
                                      <img src={src} alt="Create New" />
                                      <div className="mt-3">
                                        <h2>Create New</h2>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Container>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Container>
          <ModelForm
            isOpen={newContactModal}
            toggle={this.toggleNewContactModal}
            handleSubmit={this.handleFormSubmit}
            fields={fields}
            errors={errors}
            title="New Contact"
            handleInputChange={this.handleInputChange}
          />
          <ModelForm
            isOpen={noteModal}
            toggle={this.toggleNoteModal}
            handleSubmit={this.handleNoteSubmit}
            fields={notefields}
            errors={errors}
            title="Add Note"
            handleInputChange={this.handleInputChange}
          />
          <ModelForm
            isOpen={channelModal}
            toggle={this.toggleChannelModal}
            handleSubmit={this.handleChannelSubmit}
            fields={channelFields}
            errors={errors}
            title="Add New Channel"
            handleInputChange={this.handleInputChange}
          />
          <ModelContact
            isOpen={isaddUserModalOpen}
            toggle={this.toggleaddUserModal}
            title="Add Members"
            contacts={chats}
            members={channelMembers}
            onSubmit={this.handleAddUserSubmit}
          />
        <Modal isOpen={showSelectedMemberModal} className="modal-dialog-centered">
          <div className="bg-modal">
            <ModalHeader className="modal-header-custom pb-1">
              <h2>User Details</h2>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseModal}>
                <span aria-hidden="true">&times;</span>
              </button>
            </ModalHeader>
            <ModalBody className="custom-modal-body">
              {selectedMember ? (
                <>
                  <div className="text-center">
                    <img
                        src={selectedMember.avatar ? selectedMember.avatar : user1}
                        alt={selectedMember.nickname}
                        className="rounded-circle member-detail member-ava"
                      />
                    <p class="m-0">
                      <Label>Name:</Label>{" "} {selectedMember.nickname || "N/A"}
                    </p>
                    <p class="m-0">
                      <Label>Address:</Label> {selectedMember.identityId}
                    </p>
                  </div>                                               
                </>
              ) : null}
              </ModalBody>
            </div>
          </Modal>
          <input
            type="file"
            accept="image/*,video/*"
            id="fileInput"
            ref={this.fileInput}
            style={{ display: "none" }}
            onChange={this.handleFileChanged}
          />
          <input
            type="file"
            accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.zip,.rar,.json"
            id="docInput"
            ref={this.docInput}
            style={{ display: "none" }}
            onChange={this.handleFileChanged}
          />
        </div>
      </React.Fragment>
    );
  }
}

Chat.propTypes = {
  chats: PropTypes.array,
  groups: PropTypes.array,
  contacts: PropTypes.array,
  messages: PropTypes.array,
  onGetChats: PropTypes.func,
  onGetGroups: PropTypes.func,
  onGetContacts: PropTypes.func,
  onGetMessages: PropTypes.func,
  onAddMessage: PropTypes.func,
  updateContact: PropTypes.func,
};

const mapStateToProps = ({ chat, User, contact}) => ({
  chats: chat.chats,
  groups: chat.groups,
  contacts: chat.contacts,
  messages: chat.messages,
  user: User.user,
  channelSuccess: contact.channelSuccess,
  createContactSuccess: contact.createContactSuccess,
  createContactError: contact.createContactError,
  channel:contact.channel
});

const mapDispatchToProps = (dispatch) => ({
  onGetChats: () => dispatch(getChats()),
  onGetGroups: () => dispatch(getGroups()),
  onGetContacts: (searchQuery) => dispatch(getContacts(searchQuery)),
  onGetMessages: (receiver, sender, channelId) =>
    dispatch(getMessages(receiver, sender, channelId)),
  clearChat: (receiver, sender, channelId) => dispatch(clearChat(receiver, sender, channelId)),
  onAddMessage: (message,id) => dispatch(addMessage(message,id)),
  createContact: (newContactName, newContactAddress,callback) =>
    dispatch(createContact(newContactName, newContactAddress,callback)),
  updateBlocked: (contactId, isBlocked) =>
    dispatch(updateBlocked(contactId, isBlocked)),
  updateMuted: (contactId, isMuted) =>
    dispatch(updateMuted(contactId, isMuted)),
  updateNote: (contactId, note) => dispatch(updateNote(contactId, note)),
  updateContact: (contactId, value, field, itemType,callback) =>
    dispatch(updateContact(contactId, value, field, itemType,callback)),
  deleteChat: (id, type) => dispatch(deleteChat(id, type)),
  selectUser: (user) => dispatch(selectUser(user)),
  createChannel: (newChannelName, avatar, description, createdBy) =>
    dispatch(createChannel(newChannelName, avatar, description, createdBy)),
  addChannelUser: (channelId, members,callback) =>
    dispatch(addChannelUser(channelId, members,callback)),
  removeChannelUser: (channelId, address) =>
    dispatch(removeChannelUser(channelId, address)),
  changeChannelAvatar: (avatar, channelId) =>
    dispatch(changeChannelAvatar(avatar, channelId)),
  updateChannel: (channelData,callback) =>dispatch(updateChannel(channelData,callback)),
  messageRead: (message) =>dispatch(messageRead(message)),
  updateUnreadCount: (id,unreadCount) =>dispatch(updateUnreadCount(id,unreadCount)),
  removeMember: (channelId, address ,callback) =>
    dispatch(removeMember(channelId, address,callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Chat);