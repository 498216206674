import React, { Component } from "react";

// MetisMenu
import { Label, Input } from "reactstrap";

import MetisMenu from "metismenujs";
// import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import user2 from "../../assets/images/user-img.png";
//i18n
import { withTranslation } from 'react-i18next';

//Import logo Images
import logosmdark from "../../assets/images/logo-sm-dark.png";
import logodark from "../../assets/images/logo-dark.png";
import logosmlight from "../../assets/images/logo-sm-light.png";
import logolight from "../../assets/images/logo-light.png";
import messages from "../../assets/images/Message.svg";
import dollar from "../../assets/images/Fianance.svg";
import settings from "../../assets/images/Settings.svg";
import tools from "../../assets/images/Tools.svg";


import { connect } from "react-redux";
import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeLayoutWidth,
  changePreloader,
  changeLayoutTheme
} from "../../store/actions";
import withRouter from "../Common/withRouter";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathName: this.props.router.location.pathname,
      theme: "light"
    };
    this.changeLayoutTheme = this.changeLayoutTheme.bind(this);
  }

  componentDidMount() {
    this.initMenu();
  }

  UNSAFE_componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
        if (this.props.type !== prevProps.type) {
            this.initMenu();
        }
    }
    if (this.props.router.location.pathname !== prevProps.router.location.pathname) {
      this.setState({ pathName: this.props.router.location.pathname }, () => {
        this.initMenu();
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");
    const { pathName } = this.state;
    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }
 
  
  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;
  
    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;
  
      if (parent2) {
        parent2.classList.add("mm-show");
  
        const parent3 = parent2.parentElement;
  
        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };
  // change theme Mode
  changeLayoutTheme(e) {
    const newTheme = e.target.value || (this.state.theme === "light" ? "dark" : "light");
    this.setState({ theme: newTheme });
    this.props.changeLayoutTheme(newTheme);
  }

  render() {
    const { user, isOpened } = this.props;
    const { theme } = this.state;
    const avatarURL = user.identity.avatar ? user.identity.avatar : user2;
    const { router } = this.props;
    const path = router.location.pathname;
    const isActiveRoute = (route) => path.startsWith(route);

    return (
      <React.Fragment>
        <div className="navbar-brand-box">
          <Link to="#" className="logo logo-dark">
              <span className="logo-sm">
                  <img src={logosmdark} alt="" style={{ width: '100%' }} />
              </span>
              <span className="logo-lg">
                  <img src={logodark} alt="" style={{ width: '100%' }} />
              </span>
          </Link>

          <Link to="#" className="logo logo-light">
              <span className="logo-sm">
                  <img src={logosmlight} alt="" style={{ width: '100%' }} />
              </span>
              <span className="logo-lg">
                  <img src={logolight} alt="" style={{ width: '100%' }} />
              </span>
          </Link>
        </div>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled side-margin mbl-scr" id="side-menu">
          <li className={`${isActiveRoute('/messages') ? 'mm-active' : ''}`}>
          <Link to="/messages" className={`waves-effect icons-side msg-point ${isActiveRoute('/messages') ? 'active' : ''}`}>
            <img src={messages} alt="" className="message-icon" />
            <span className="ms-2">{this.props.t('Messages')}</span>
          </Link>
        </li>

            <li className={`${isActiveRoute('/finance') ? 'mm-active' : ''}`}>
              <Link to="/finance" className={`waves-effect icons-side ${isActiveRoute('/finance') ? 'active' : ''}`}>
                <img src={dollar} alt="" className="message-icon"/>
                <span className="ms-2">{this.props.t('Finance')}</span>
              </Link>
            </li>

            <li className={`${isActiveRoute('/settings') ? 'mm-active' : ''}`}>
              <Link to="/settings" className={`waves-effect icons-side ${isActiveRoute('/settings') ? 'active' : ''}`}>
                <img src={settings} alt="" className="message-icon"/>
                <span className="ms-2">{this.props.t('Settings')}</span>
              </Link>
            </li>
            <li className={`${isActiveRoute('/tools') ? 'mm-active' : ''}`}>
              <Link to="/tools" className="waves-effect icons-side icon-size">
                <img src={tools} alt="" className="message-icon"/>
                <span className="ms-2">{this.props.t('Tools')}</span>
              </Link>
            </li>
          </ul>
          <ul className="metismenu list-unstyled bottom-sidebar-part" id="side-menu">
          <li>
            {isOpened ? (
              <div className="radio-toolbar">
                <Input
                  type="radio"
                  id="radioThemeLightMode"
                  name="radioThemeMode"
                  value="light"
                  checked={theme === "light"}
                  onClick={this.changeLayoutTheme}
                  onChange={() => {}} // Dummy onChange to avoid warning
                />
                <Label
                  className={`light-cls ${theme === "light" ? "selected-theme" : ""}`}
                  htmlFor="radioThemeLightMode"
                >
                  Light
                </Label>
                {"   "}
                <Input
                  type="radio"
                  id="radioThemeDarkMode"
                  name="radioThemeMode"
                  value="dark"
                  checked={theme === "dark"}
                  onClick={this.changeLayoutTheme}
                  onChange={() => {}} // Dummy onChange to avoid warning
                />
                <Label
                  className={`dark-cls ${theme === "dark" ? "selected-theme" : ""}`}
                  htmlFor="radioThemeDarkMode"
                >
                  Dark
                </Label>
              </div>
            ) : (
              <Link
                to="#"
                className="waves-effect icon-size bulb-icon-container"
                onClick={this.changeLayoutTheme}
              >
                <i
                  className={`${
                    theme === "dark"
                      ? "ri-lightbulb-line"
                      : "ri-lightbulb-flash-fill text-warning"
                  }`}
                ></i>
              </Link>
            )}
          </li>
            <li className={`${isActiveRoute('/profile') ? 'mm-active' : ''}`}>
              {isOpened ? (
                  <Link to="/profile" className={`waves-effect name-id ${isActiveRoute('/profile') ? 'active' : ''}`} style={{ marginBottom: '17px' }} >
                    <span className="logo-sm user-img-cls rounded-circle">
                         <img src={avatarURL} alt="user-icon" width="30" height="30"/>
                    </span>
                    <span className="ms-1">
                      <small>
                        {user && user.identity && user.identity.address ? user.identity.address.substring(0, 11).toUpperCase() : 'N/A'}..
                      </small>
                    </span>
                    <span className="id-names"><i className=" ri-arrow-right-s-line"></i></span>
                  </Link>
                ) : (
                  <Link to="/profile" className="waves-effect icon-profile" style={{ marginBottom: '18px' }} >
                      <img 
                        src={avatarURL} 
                        alt="user-icon" 
                        className="ava-custom"
                      />
                  </Link>
                )}
            </li>
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  return {  
      ...state.Layout,    
      user: state.User.user // Access user state from Redux store
  };
};

export default withRouter(connect(mapStatetoProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeLayoutWidth,
  changePreloader,  
  changeLayoutTheme,
})(withTranslation()(SidebarContent)));
